import React from 'react'
import { Router } from '@reach/router'
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { Link } from "gatsby"

const Home = ({ user }) => {
  return <p>Hi, {user.name ? user.name : "friend"}!</p>
}
const Settings = () => <p>Settings</p>
const Billing = () => <p>Billing</p>

export default class Account extends React.Component {
  componentDidMount() {
    if (!isAuthenticated()) {
      localStorage.setItem("redirectUri", window.location.pathname)
      login()
      return <p>Redirecting to login...</p>
    }
  }

  render() {
    const user = getProfile()

    return (
      <>
        <nav>
          <Link to="/">Home</Link> <Link to="/account/settings">Settings</Link>{" "}
          <Link to="/account/billing">Billing</Link>{" "}
          <a
            href="#logout"
            onClick={e => {
              logout(window.location.origin)
              e.preventDefault()
            }}
          >
            Log Out
          </a>
        </nav>
        <Router>
          <Home path="/account" user={user} />
          <Settings path="/account/settings" />
          <Billing path="/account/billing" />
        </Router>
      </>
    )
  }
}
